.react-table {
  @apply w-full mb-3 flex flex-col;
  .thead {
    @apply whitespace-nowrap;
    .tr {
      position: relative;
      @apply flex flex-1;
      .th {
        position: relative;
        @apply text-base font-bold;
        flex-shrink: 0;
        @apply border-b border-dashed;

        @apply bg-white;
        .resizer {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 14px;
          width: 5px;
          border-left: solid 1px rgba(0, 0, 0, 0.5);
          cursor: col-resize;
          user-select: none;
          touch-action: none;
        }
      }
    }
  }
  .tbody {
    overflow: auto;
    .tr {
      position: relative;
      height: 48px;
      box-shadow: inset 5px 0px 0px transparent;
      @apply rounded transition items-center flex;
      &:hover {
        box-shadow: inset 5px 0px 0px theme("colors.primary");
        @apply bg-opacity-10 bg-primary;
      }
      .td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @apply bg-white;
        &:hover {
          @apply bg-opacity-10 bg-primary;
        }
      }
    }
  }
}
