.switch-input {
    appearance: none;
    width: 0;
    height: 0;
    & + .switch {
        @apply rounded-full mr-1;
        width: 4rem;
        height: 1.5rem;
        position: relative;
        box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);
        &:after {
            content: "";
            position: absolute;
            width: 2rem;
            height: 1rem;
            top: 0;
            bottom: 0;
            left: 0.25rem;
            margin: auto;
            @apply bg-white rounded-full transition-all;
        }
    }
    &:checked {
        & + .switch {
            // @apply bg-primary;
            &:after {
                left: 1.75rem;
            }
        }
    }
}
