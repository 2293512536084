$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../../fonts" !default;

$icon-sort-default: "\e962";
$icon-sort-asc: "\e963";
$icon-sort-desc: "\e964";
$icon-Icon-ionic-paper-plane: "\e961";
$icon-star_filed_icn: "\e907";
$icon-location: "\e95f";
$icon-shipping-address: "\e960";
$icon-address: "\e95d";
$icon-credit-card: "\e95e";
$icon-document-pdf: "\e95b";
$icon-diagram-project-solid: "\e958";
$icon-email-lookup: "\e959";
$icon-globe: "\e956";
$icon-paper-plane: "\e957";
$icon-minus: "\e955";
$icon-user-lock: "\e952";
$icon-search-file: "\e951";
$icon-check: "\e950";
$icon-person: "\e94f";
$icon-persona_icn: "\e94e";
$icon-fullscreen: "\e94d";
$icon-camera: "\e94c";
$icon-plus-circle: "\e94b";
$icon-reload: "\e905";
$icon-tag: "\e949";
$icon-pause: "\e946";
$icon-back: "\e947";
$icon-play: "\e945";
$icon-approved: "\e93f";
$icon-paid: "\e940";
$icon-attempt: "\e941";
$icon-close-outline: "\e93e";
$icon-link: "\e93b";
$icon-chat-dots: "\e923";
$icon-hammer: "\e93a";
$icon-file: "\e936";
$icon-external-link: "\e939";
$icon-export1: "\e938";
$icon-import: "\e937";
$icon-reportlist: "\e901";
$icon-file-word: "\e942";
$icon-file-excel: "\e943";
$icon-mail-read: "\e944";
$icon-download: "\e934";
$icon-email: "\e92e";
$icon-file-upload: "\e935";
$icon-searchplus: "\e92c";
$icon-times: "\e931";
$icon-searchminus: "\e92d";
$icon-info-circle: "\e932";
$icon-plus: "\e92f";
$icon-edit: "\e92a";
$icon-filter: "\e928";
$icon-score-fill: "\e926";
$icon-phone: "\e927";
$icon-escalate-large: "\e913";
$icon-open-large: "\e94a";
$icon-onhold-large: "\e922";
$icon-pending-large: "\e925";
$icon-upload: "\e92b";
$icon-copy: "\e91f";
$icon-mail: "\e920";
$icon-search-plus: "\e95c";
$icon-search-minus: "\e93d";
$icon-user-insight: "\e924";
$icon-pin: "\e921";
$icon-thumbs-o-down: "\e953";
$icon-thumbs-o-up: "\e954";
$icon-trash: "\e929";
$icon-eye: "\e91d";
$icon-clock: "\e95a";
$icon-rule-setting: "\e948";
$icon-logout1: "\e93c";
$icon-indicator: "\e930";
$icon-arrow-down: "\e90a";
$icon-arrow-up: "\e91c";
$icon-escalate: "\e91e";
$icon-arrow-left: "\e91a";
$icon-arrow-right: "\e91b";
$icon-bell: "\e900";
$icon-calendar: "\e902";
$icon-cluster: "\e903";
$icon-dashboard: "\e904";
$icon-export: "\e906";
$icon-filte: "\e90b";
$icon-list: "\e908";
$icon-logout: "\e909";
$icon-nexus: "\e914";
$icon-onhold: "\e90c";
$icon-pending: "\e933";
$icon-persona: "\e90d";
$icon-refresh: "\e90e";
$icon-reports: "\e90f";
$icon-review-panel: "\e910";
$icon-rulengin: "\e911";
$icon-search: "\e912";
$icon-settings: "\e915";
$icon-site-group-summery: "\e916";
$icon-star: "\e917";
$icon-transaction: "\e918";
$icon-open: "\e919";
