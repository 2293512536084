$checkImg: "data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E";
$radioImg: "data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E";
$indeterminateImg: "data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox='0%200%2016%2016'%20fill='%23abb0ba'%20xmlns='http://www.w3.org/2000/svg'%3E%3Crect%20x=%223%22%20y=%227%22%20width=%2210%22%20height=%222%22%20rx=%221%22/%3E%3C/svg%3E";
.form-control {
    @apply border transition;
    &:focus {
        @apply outline-none;
    }
    &.checkbox {
        @apply border;
        width: 1.25rem;
        height: 1.25rem;
        appearance: none;
        border-radius: 0.25rem;
        &[indeterminate="true"] {
            background-image: url($indeterminateImg);
        }
        &:checked {
            background-image: url($checkImg);
            cursor: pointer;
        }
    }
    &.radio {
        @apply border rounded-full;
        width: 1.25rem;
        height: 1.25rem;
        appearance: none;
        &:checked {
            background-image: url($radioImg);
            cursor: pointer;
        }
    }
}

.range-slider {
    @apply w-full;
    /********** Range Input Styles **********/
    /*Range Reset*/
    &[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
    }

    // /* Removes default focus */
    &[type="range"]:focus {
        outline: none;
    }

    // /***** Chrome, Safari, Opera and Edge Chromium styles *****/
    // /* slider track */
    &[type="range"]::-webkit-slider-runnable-track {
        background-color: theme("colors.slate.200");
        border-radius: 0.5rem;
        height: 0.5rem;
    }

    // /* slider thumb */
    &[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -4px; /* Centers thumb on the track */

        /*custom styles*/
        background-color: theme("colors.primary");
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
    }

    &[type="range"]:focus::-webkit-slider-thumb {
        border: 1px solid #fff;
        outline: 1px solid theme("colors.primary");
        // outline-offset: 0.125rem;
    }
}
