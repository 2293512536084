.modal-container {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 949;

    .modal {
        position: fixed;
        top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;
        overflow: auto;
        z-index: 999;
        &-content {
            &-sm {
                width: theme("screens.sm");
            }
            &-md {
                width: theme("screens.md");
            }
            &-lg {
                width: theme("screens.lg");
            }
            &-xl {
                width: theme("screens.xl");
            }
        }
    }
    .modal-backdrop {
        @apply bg-slate-800 bg-opacity-40;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 949;
    }
}
