@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7imsis');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7imsis#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7imsis') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7imsis') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7imsis##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sort-default {
  &:before {
    content: $icon-sort-default; 
  }
}
.icon-sort-asc {
  &:before {
    content: $icon-sort-asc; 
  }
}
.icon-sort-desc {
  &:before {
    content: $icon-sort-desc; 
  }
}
.icon-Icon-ionic-paper-plane {
  &:before {
    content: $icon-Icon-ionic-paper-plane; 
  }
}
.icon-star_filed_icn {
  &:before {
    content: $icon-star_filed_icn; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-shipping-address {
  &:before {
    content: $icon-shipping-address; 
  }
}
.icon-address {
  &:before {
    content: $icon-address; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-document-pdf {
  &:before {
    content: $icon-document-pdf; 
  }
}
.icon-diagram-project-solid {
  &:before {
    content: $icon-diagram-project-solid; 
  }
}
.icon-email-lookup {
  &:before {
    content: $icon-email-lookup; 
  }
}
.icon-globe {
  &:before {
    content: $icon-globe; 
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-user-lock {
  &:before {
    content: $icon-user-lock; 
  }
}
.icon-search-file {
  &:before {
    content: $icon-search-file; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-person {
  &:before {
    content: $icon-person; 
  }
}
.icon-persona_icn {
  &:before {
    content: $icon-persona_icn; 
  }
}
.icon-fullscreen {
  &:before {
    content: $icon-fullscreen; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-reload {
  &:before {
    content: $icon-reload; 
  }
}
.icon-tag {
  &:before {
    content: $icon-tag; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-back {
  &:before {
    content: $icon-back; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-approved {
  &:before {
    content: $icon-approved; 
  }
}
.icon-paid {
  &:before {
    content: $icon-paid; 
  }
}
.icon-attempt {
  &:before {
    content: $icon-attempt; 
  }
}
.icon-close-outline {
  &:before {
    content: $icon-close-outline; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-chat-dots {
  &:before {
    content: $icon-chat-dots; 
  }
}
.icon-hammer {
  &:before {
    content: $icon-hammer; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link; 
  }
}
.icon-export1 {
  &:before {
    content: $icon-export1; 
  }
}
.icon-import {
  &:before {
    content: $icon-import; 
  }
}
.icon-reportlist {
  &:before {
    content: $icon-reportlist; 
  }
}
.icon-file-word {
  &:before {
    content: $icon-file-word; 
  }
}
.icon-file-excel {
  &:before {
    content: $icon-file-excel; 
  }
}
.icon-mail-read {
  &:before {
    content: $icon-mail-read; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-file-upload {
  &:before {
    content: $icon-file-upload; 
  }
}
.icon-searchplus {
  &:before {
    content: $icon-searchplus; 
  }
}
.icon-times {
  &:before {
    content: $icon-times; 
  }
}
.icon-searchminus {
  &:before {
    content: $icon-searchminus; 
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-score-fill {
  &:before {
    content: $icon-score-fill; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-escalate-large {
  &:before {
    content: $icon-escalate-large; 
  }
}
.icon-open-large {
  &:before {
    content: $icon-open-large; 
  }
}
.icon-onhold-large {
  &:before {
    content: $icon-onhold-large; 
  }
}
.icon-pending-large {
  &:before {
    content: $icon-pending-large; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-search-plus {
  &:before {
    content: $icon-search-plus; 
  }
}
.icon-search-minus {
  &:before {
    content: $icon-search-minus; 
  }
}
.icon-user-insight {
  &:before {
    content: $icon-user-insight; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-thumbs-o-down {
  &:before {
    content: $icon-thumbs-o-down; 
  }
}
.icon-thumbs-o-up {
  &:before {
    content: $icon-thumbs-o-up; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-rule-setting {
  &:before {
    content: $icon-rule-setting; 
  }
}
.icon-logout1 {
  &:before {
    content: $icon-logout1; 
  }
}
.icon-indicator {
  &:before {
    content: $icon-indicator; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-escalate {
  &:before {
    content: $icon-escalate; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-cluster {
  &:before {
    content: $icon-cluster; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-export {
  &:before {
    content: $icon-export; 
  }
}
.icon-filte {
  &:before {
    content: $icon-filte; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-nexus {
  &:before {
    content: $icon-nexus; 
  }
}
.icon-onhold {
  &:before {
    content: $icon-onhold; 
  }
}
.icon-pending {
  &:before {
    content: $icon-pending; 
  }
}
.icon-persona {
  &:before {
    content: $icon-persona; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-reports {
  &:before {
    content: $icon-reports; 
  }
}
.icon-review-panel {
  &:before {
    content: $icon-review-panel; 
  }
}
.icon-rulengin {
  &:before {
    content: $icon-rulengin; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-site-group-summery {
  &:before {
    content: $icon-site-group-summery; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-transaction {
  &:before {
    content: $icon-transaction; 
  }
}
.icon-open {
  &:before {
    content: $icon-open; 
  }
}

