@import "../react-datepicker/variables.scss";
@import "../react-datepicker/mixins.scss";
// font-family: $datepicker__font-family;
// font-size: $datepicker__font-size;

$rdl-icon-font-family-fa5: "Font Awesome 5 Free", "FontAwesome", sans-serif;
$rdl-icon-font-family-fa6: "Font Awesome 6 Free", "FontAwesome", sans-serif;
$rdl-input-bg: #fff !default;
$rdl-input-disabled-bg: #eee !default;
$rdl-input-border: #ccc !default;
$rdl-input-border-active: darken($rdl-input-border, 25%) !default;
$rdl-input-border-hover: darken($rdl-input-border, 12%) !default;
$rdl-input-color: #333 !default;
$rdl-btn-bg: $rdl-input-bg !default;
$rdl-btn-bg-active: darken($rdl-btn-bg, 10%) !default;
$rdl-btn-border: $rdl-input-border !default;
$rdl-btn-border-active: $rdl-input-border-active !default;
$rdl-btn-border-hover: $rdl-input-border-hover !default;
$rdl-btn-color: #333 !default;
$rdl-line-height: 1.42857 !default;
$rdl-desktop-min-width: 576px !default;

// Force ASCII output until Sass supports it
// https://github.com/sass/dart-sass/issues/568
@function unicode($str) {
  @return unquote('"') + unquote(str-insert($str, "\\", 1)) + unquote('"');
}

.react-dual-listbox {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  input,
  select {
    &:disabled {
      background: $rdl-input-disabled-bg;
      cursor: not-allowed;
    }
  }

  button {
    // background-color: $datepicker__selected-color;
    // color: #eee;
    // border-radius: 5px;
    // font-family: $datepicker__font-family;
    // : {
    //   background-color: $datepicker__selected-color;
    // }
  }
  select {
    line-height: $datepicker__item-size;
    font-family: $datepicker__font-family;
  }

  // Set minimum height to prevent boxes from resizing on mobile
  select {
    min-height: 300px;
  }
}

.rdl-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (min-width: $rdl-desktop-min-width) {
    flex-direction: row;
  }
}

.rdl-hidden-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.rdl-list-box {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-self: stretch;
}

.rdl-filter,
.rdl-control {
  display: block;
  border: 1px solid $rdl-input-border;
  border-radius: 2px;
  padding: 8px 12px;
  width: 100%;
  color: $rdl-input-color;
  font-size: 14px;
}

.rdl-filter {
  margin-bottom: 10px;

  &:focus:not(:disabled) {
    outline: 0;
    border-color: $rdl-input-border-active;
  }
}

.rdl-control-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.rdl-control-label {
  padding: 0 4px 4px;

  &.rdl-sr-only {
    position: absolute;
    clip: rect(0 0 0 0);
  }
}

.rdl-control {
  flex: 1 0 auto;

  &:focus:not(:disabled) {
    outline: 0;
    border-color: $rdl-input-border-active;
  }

  optgroup {
    font: inherit;
    font-weight: 700;
  }
}

.rdl-actions {
  display: flex;
  flex: 0 0 auto;
  margin: 10px 0;

  @media (min-width: $rdl-desktop-min-width) {
    flex-direction: column;
    margin: 0 10px;
    width: auto;

    .rdl-has-header & {
      padding-top: 31px;
    }

    .rdl-has-filter & {
      padding-top: 46px;
    }

    .rdl-has-header.rdl-has-filter & {
      padding-top: 77px;
    }
  }
}

.rdl-actions-right,
.rdl-actions-left {
  display: flex;

  @media (min-width: $rdl-desktop-min-width) {
    flex-direction: column;
  }
}

@media (min-width: $rdl-desktop-min-width) {
  .rdl-actions-right {
    margin-bottom: 10px;
  }
}

.rdl-btn {
  margin: 0 3px;
  border: 1px solid $rdl-btn-border;
  border-radius: 2px;
  background: $rdl-input-bg;
  cursor: pointer;
  padding: 5px 10px;
  color: $rdl-btn-color;
  font-size: 14px;

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    border-color: $rdl-btn-border-active;
    background: $rdl-btn-bg-active;
  }

  &:focus:not(:disabled) {
    outline: thin dotted;
    outline-offset: -2px;
  }

  &:hover:not(:disabled) {
    border-color: $rdl-btn-border-hover;
    background: $rdl-btn-bg-active;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .rdl-icon {
    display: inline-block;
    text-align: center;
    text-rendering: auto;
    font-size: 14px;
    font-variant: normal;
    font-style: normal;
  }

  @media (min-width: $rdl-desktop-min-width) {
    margin: 0 0 5px;
  }
}

.rdl-align-top {
  .rdl-available {
    margin-bottom: 10px;
  }

  .rdl-control {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .rdl-actions-left,
  .rdl-actions-right {
    flex-direction: row;
    margin: 0;
  }

  .rdl-list-box .rdl-move {
    flex: 0 1 50%;
    margin: 0;

    &:first-child {
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &[dir="ltr"] {
    .rdl-list-box .rdl-move {
      &:first-child {
        border-right: 0;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
      }
    }
  }

  &[dir="rtl"] {
    .rdl-list-box .rdl-move {
      &:first-child {
        border-left: 0;
        border-top-left-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
      }
    }
  }

  @media (min-width: $rdl-desktop-min-width) {
    &[dir="ltr"] {
      .rdl-available {
        margin: 0 10px 0 0;
      }

      .rdl-selected {
        margin-left: 10px;
      }
    }

    &[dir="rtl"] {
      .rdl-available {
        margin: 0 0 0 10px;
      }

      .rdl-selected {
        margin-right: 10px;
      }
    }
  }
}

// Font Awesome styles
.rdl-icons-fa5,
.rdl-icons-fa6 {
  .rdl-icon {
    font-weight: 900;
  }

  .rdl-icon-move-all-right::before,
  .rdl-icon-move-bottom::before {
    content: unicode("f103");
  }

  .rdl-icon-move-right::before,
  .rdl-icon-move-down::before {
    content: unicode("f107");
  }

  .rdl-icon-move-left::before,
  .rdl-icon-move-up::before {
    content: unicode("f106");
  }

  .rdl-icon-move-all-left::before,
  .rdl-icon-move-top::before {
    content: unicode("f102");
  }

  // Larger screens will arrange the boxes as columns, so "left" and "right" icons apply
  @media (min-width: $rdl-desktop-min-width) {
    .rdl-icon-move-left::before {
      content: unicode("f104");
    }

    .rdl-icon-move-all-left::before {
      content: unicode("f100");
    }

    .rdl-icon-move-right::before {
      content: unicode("f105");
    }

    .rdl-icon-move-all-right::before {
      content: unicode("f101");
    }

    // Change directions when right-to-left
    &[dir="rtl"] {
      .rdl-icon-move-left::before {
        content: unicode("f105");
      }

      .rdl-icon-move-all-left::before {
        content: unicode("f101");
      }

      .rdl-icon-move-right::before {
        content: unicode("f104");
      }

      .rdl-icon-move-all-right::before {
        content: unicode("f100");
      }
    }
  }
}

.rdl-icons-fa5 .rdl-icon {
  font-family: $rdl-icon-font-family-fa5;
}

.rdl-icons-fa6 .rdl-icon {
  font-family: $rdl-icon-font-family-fa6;
}

.rdl-icons-native {
  .rdl-icon-move-all-right::before,
  .rdl-icon-move-bottom::before {
    content: "⮇";
  }

  .rdl-icon-move-right::before,
  .rdl-icon-move-down::before {
    content: "🠣";
  }

  .rdl-icon-move-left::before,
  .rdl-icon-move-up::before {
    content: "🠡";
  }

  .rdl-icon-move-all-left::before,
  .rdl-icon-move-top::before {
    content: "⮅";
  }

  // Larger screens will arrange the boxes as columns, so "left" and "right" icons apply
  @media (min-width: $rdl-desktop-min-width) {
    .rdl-icon-move-left::before {
      content: "⭠";
    }

    .rdl-icon-move-all-left::before {
      content: "⮄";
    }

    .rdl-icon-move-right::before {
      content: "⭢";
    }

    .rdl-icon-move-all-right::before {
      content: "⮆";
    }

    // Change directions when right-to-left
    &[dir="rtl"] {
      .rdl-icon-move-left::before {
        content: "⭢";
      }

      .rdl-icon-move-all-left::before {
        content: "⮆";
      }

      .rdl-icon-move-right::before {
        content: "⭠";
      }

      .rdl-icon-move-all-right::before {
        content: "⮄";
      }
    }
  }
}
