.card {
    @apply rounded-md border shadow;
}
.close-button {
    @apply text-xs text-primary rounded-full bg-white w-6 h-6 flex items-center justify-center cursor-pointer transition;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    &:hover {
        @apply bg-primary bg-opacity-20;
    }
}
.card-header,
.card-footer {
    @apply border-b bg-slate-100 py-2 px-4;
}

.card-body {
    @apply p-4;
}
